import React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import {graphql, useStaticQuery} from "gatsby";
import BlockContent from '@sanity/block-content-to-react';
import Testimonial from "../components/Testimonial";
import {center, left, right} from "../marks/alignment";

export default ({ location }) => {
    const { sanityContact, allSanityTestimonial } = useStaticQuery(graphql`
        query {
            sanityContact {
                pageTitle
                _rawContent
            }
            allSanityTestimonial(sort: {fields: date, order: DESC}) {
                edges {
                  node {
                    author
                    date
                    location
                    quote
                  }
                }
            }
        }
    `);

    const contactContent = sanityContact;
    const testimonials = allSanityTestimonial.edges.map((testimonial) => {
        return {
            ...testimonial.node,
            date: new Date(Date.parse(testimonial.node.date))
        }
    });

    return (
      <Layout location={location}>
            <SEO title={contactContent.pageTitle}/>
            <div className={"flex flex-col md:flex-row justify-between"}>
              <div>
                <h1 className={"text-lg page-label mb-4 flex-1 mr-8"}>{contactContent.pageTitle}</h1>
                <hr className={"border-gray-800 mb-4 block md:hidden"}/>
                <div className={"contact-details flex-1 mr-8 block md:hidden"}>
                  <BlockContent blocks={contactContent._rawContent} serializers={{marks: {left, center, right}}}/>
                </div>
              </div>
              <div>
                <h1 className={"text-lg page-label mb-4 flex-1"}>{"Testimonials"}</h1>
                <hr className={"border-gray-800 mb-4 block md:hidden"}/>
                <ul className={"flex flex-1 w-full flex-col block md:hidden"}>
                  {testimonials.map((testimonial, index) =>
                    <Testimonial key={index} testimonial={testimonial}/>
                  )}
                </ul>
              </div>

            </div>
            <hr className={"border-gray-800 mb-4 hidden md:block"}/>
            <div className={"flex"}>
                <div className={"contact-details flex-1 mr-8 hidden md:block"}>
                    <BlockContent blocks={contactContent._rawContent} serializers={{marks: {left, center, right}}}/>
                </div>
                <ul className={"flex flex-1 w-full flex-col hidden md:block"}>
                    {testimonials.map((testimonial, index) =>
                        <Testimonial key={index} testimonial={testimonial}/>
                        )}
                </ul>
            </div>
        </Layout>
    )
}
